import React, { useRef } from "react";
import {shaderMaterial, useGLTF} from "@react-three/drei";
import {useMemo} from "react";
import {useControls} from "leva";
import * as THREE from 'three'
import {extend, useFrame} from "@react-three/fiber";


export function Portal(props) {
  const portalMaterial = useRef()
  useFrame((state, delta) =>
  {
    portalMaterial.current.uTime += delta
  })

  const options = useMemo(() => {
    return {
      position_x: {value: 2, min: -100, max: 100, step: 1},
      position_y: {value: -5.4, min: -400, max: 100, step: 1},
      position_z: {value: -7, min: -100, max: 100, step: 1},
      rotation_x: {value: 339, min: 0, max: 360, step: 1},
      rotation_y: {value: 314, min: 0, max: 360, step: 1},
      rotation_z: {value: 2, min: 0, max: 360, step: 1},
    }
  }, [])

  const circle_options = useMemo(() => {
    return {
      position_x: {value: 2, min: -100, max: 100, step: 1},
      position_y: {value: -3.1, min: -400, max: 100, step: 1},
      position_z: {value: -8, min: -100, max: 100, step: 1},
      rotation_x: {value: 340, min: 0, max: 360, step: 1},
      rotation_y: {value: 321, min: 0, max: 360, step: 1},
      rotation_z: {value: 2, min: 0, max: 360, step: 1},
    }
  }, [])
  const circle_parameters = useControls('circle position controls', circle_options)

  const position_parameters = useControls('portal position controls', options)

  return (
    <>
    <mesh
      position={[
        props.position[0] + position_parameters.position_x,
        props.position[1] + position_parameters.position_y,
        props.position[2] + position_parameters.position_z
      ]}
      rotation={[position_parameters.rotation_x * (Math.PI/180), position_parameters.rotation_y * (Math.PI/180), position_parameters.rotation_z * (Math.PI/180)]}

    >
      <Model/>
    </mesh>

      <mesh
        position={[
          props.position[0] + circle_parameters.position_x,
          props.position[1] + circle_parameters.position_y,
          props.position[2] + circle_parameters.position_z
        ]}
        rotation={[circle_parameters.rotation_x * (Math.PI/180), circle_parameters.rotation_y * (Math.PI/180), circle_parameters.rotation_z * (Math.PI/180)]}

      >
        <circleGeometry args={[2]}/>
        <portalMaterial ref={portalMaterial}/>

      </mesh>
    </>

  )

}

const PortalMaterial = shaderMaterial({
    uTime: 0,
    uColorStart: new THREE.Color('#ffffff'),
    uColorEnd: new THREE.Color('#000000')
  },
  `
  varying vec2 vUv;
  
  void main()
  {
      vec4 modelPosition = modelMatrix * vec4(position, 1.0);
      vec4 viewPosition = viewMatrix * modelPosition;
      vec4 projectionPosition = projectionMatrix * viewPosition;
  
      gl_Position = projectionPosition;
  
      vUv = uv;
  }
  `,
  `
  uniform float uTime;
  uniform vec3 uColorStart;
  uniform vec3 uColorEnd;
  
  varying vec2 vUv;
  
  //    Classic Perlin 3D Noise 
  //    by Stefan Gustavson
  //
  vec4 permute(vec4 x){ return mod(((x*34.0)+1.0)*x, 289.0); }
  vec4 taylorInvSqrt(vec4 r){ return 1.79284291400159 - 0.85373472095314 * r; }
  vec3 fade(vec3 t) { return t*t*t*(t*(t*6.0-15.0)+10.0); }
  
  float cnoise(vec3 P)
  {
      vec3 Pi0 = floor(P); // Integer part for indexing
      vec3 Pi1 = Pi0 + vec3(1.0); // Integer part + 1
      Pi0 = mod(Pi0, 289.0);
      Pi1 = mod(Pi1, 289.0);
      vec3 Pf0 = fract(P); // Fractional part for interpolation
      vec3 Pf1 = Pf0 - vec3(1.0); // Fractional part - 1.0
      vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
      vec4 iy = vec4(Pi0.yy, Pi1.yy);
      vec4 iz0 = Pi0.zzzz;
      vec4 iz1 = Pi1.zzzz;
  
      vec4 ixy = permute(permute(ix) + iy);
      vec4 ixy0 = permute(ixy + iz0);
      vec4 ixy1 = permute(ixy + iz1);
  
      vec4 gx0 = ixy0 / 7.0;
      vec4 gy0 = fract(floor(gx0) / 7.0) - 0.5;
      gx0 = fract(gx0);
      vec4 gz0 = vec4(0.5) - abs(gx0) - abs(gy0);
      vec4 sz0 = step(gz0, vec4(0.0));
      gx0 -= sz0 * (step(0.0, gx0) - 0.5);
      gy0 -= sz0 * (step(0.0, gy0) - 0.5);
  
      vec4 gx1 = ixy1 / 7.0;
      vec4 gy1 = fract(floor(gx1) / 7.0) - 0.5;
      gx1 = fract(gx1);
      vec4 gz1 = vec4(0.5) - abs(gx1) - abs(gy1);
      vec4 sz1 = step(gz1, vec4(0.0));
      gx1 -= sz1 * (step(0.0, gx1) - 0.5);
      gy1 -= sz1 * (step(0.0, gy1) - 0.5);
  
      vec3 g000 = vec3(gx0.x,gy0.x,gz0.x);
      vec3 g100 = vec3(gx0.y,gy0.y,gz0.y);
      vec3 g010 = vec3(gx0.z,gy0.z,gz0.z);
      vec3 g110 = vec3(gx0.w,gy0.w,gz0.w);
      vec3 g001 = vec3(gx1.x,gy1.x,gz1.x);
      vec3 g101 = vec3(gx1.y,gy1.y,gz1.y);
      vec3 g011 = vec3(gx1.z,gy1.z,gz1.z);
      vec3 g111 = vec3(gx1.w,gy1.w,gz1.w);
  
      vec4 norm0 = taylorInvSqrt(vec4(dot(g000, g000), dot(g010, g010), dot(g100, g100), dot(g110, g110)));
      g000 *= norm0.x;
      g010 *= norm0.y;
      g100 *= norm0.z;
      g110 *= norm0.w;
      vec4 norm1 = taylorInvSqrt(vec4(dot(g001, g001), dot(g011, g011), dot(g101, g101), dot(g111, g111)));
      g001 *= norm1.x;
      g011 *= norm1.y;
      g101 *= norm1.z;
      g111 *= norm1.w;
  
      float n000 = dot(g000, Pf0);
      float n100 = dot(g100, vec3(Pf1.x, Pf0.yz));
      float n010 = dot(g010, vec3(Pf0.x, Pf1.y, Pf0.z));
      float n110 = dot(g110, vec3(Pf1.xy, Pf0.z));
      float n001 = dot(g001, vec3(Pf0.xy, Pf1.z));
      float n101 = dot(g101, vec3(Pf1.x, Pf0.y, Pf1.z));
      float n011 = dot(g011, vec3(Pf0.x, Pf1.yz));
      float n111 = dot(g111, Pf1);
  
      vec3 fade_xyz = fade(Pf0);
      vec4 n_z = mix(vec4(n000, n100, n010, n110), vec4(n001, n101, n011, n111), fade_xyz.z);
      vec2 n_yz = mix(n_z.xy, n_z.zw, fade_xyz.y);
      float n_xyz = mix(n_yz.x, n_yz.y, fade_xyz.x); 
  
      return 2.2 * n_xyz;
  }
  
  void main()
  {
      // Displace the UV
      vec2 displacedUv = vUv + cnoise(vec3(vUv * 5.0, uTime * 0.1));
  
      // Perlin noise
      float strength = cnoise(vec3(displacedUv * 5.0, uTime * 0.2));
  
      // Outer glow
      float outerGlow = distance(vUv, vec2(0.5)) * 5.0 - 1.4;
      strength += outerGlow;
  
      // Apply cool step
      strength += step(- 0.2, strength) * 0.8;
  
      // // Clamp the value from 0 to 1
      // strength = clamp(strength, 0.0, 1.0);
  
      // Final color
      vec3 color = mix(uColorStart, uColorEnd, strength);
  
      gl_FragColor = vec4(color, 1.0);
  }
  
  `
)
extend({PortalMaterial})


export function Model(props) {
  const { nodes, materials } = useGLTF("/sci-fi_portal_gateway.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_4.geometry}
        material={materials.outer_box}
        position={[3.953, 1.318, -0.001]}
        scale={0.716}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_6.geometry}
        material={materials.walkway}
        position={[0, 0.839, 0]}
        scale={0.716}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_8.geometry}
        material={materials.portal_shell}
        position={[0, 4.85, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_10.geometry}
        material={materials.portal_shell}
        position={[0, 2.5, 0]}
      />
    </group>
  );
}

useGLTF.preload("/sci-fi_portal_gateway.glb");